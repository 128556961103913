
import React, { useEffect } from 'react'
import styled from '@emotion/styled'

export const Map = () => {

  const MapArea = styled.div`
    margin: auto;
    background: #fffbef;
    border-radius: 8px / 50px;
    box-shadow: 0 0 0 3px #2c2e38 inset, 0 0 12px 7px rgba(0,0,0,0.2);
    padding: 10px;
    width: 70%;
    text-align: center;
    font-size: 20px;
    font-family: 'Srisakdi', cursive;
    min-height: 500px;
    @media (max-width: 768px) {
      width: 90%;
    }
  `

  useEffect(() => {
    const script1 = document.createElement("script")

    script1.src = "/map.js"
    script1.id = `mapScript1`

    const script = document.createElement("script")

    script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyDIdgXoi1H6qEUzq3011_kN5Gx4ifwx_4I&v=3.36&language=en&libraries=places,geometry&callback=createMap"
    script.defer = true
    script.async = true
    script.id = `mapScript`

    if (!document.getElementById('mapScript')) {
      document.body.appendChild(script1)
      document.body.appendChild(script)
    }
    window.scrollTo(0, 0)

    return function killAllHumans() {
      const s = document.getElementById('mapScript')
      const s1 = document.getElementById('mapScript1')
      if (s && s.parentNode) {
        s.parentNode.removeChild(s)
      }
      if (s1 && s1.parentNode) {
        s1.parentNode.removeChild(s1)
      }
    }
  }, [])

  return (
    <MapArea id="map" />
  )
}
