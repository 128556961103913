import React from 'react'
import styled from '@emotion/styled'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { pages, PageFooter, PageHeader } from './'
import bunting from '../../static/images/bunting.png'

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  position: relative;
  overflow-x: hidden;
`

const Bunting = styled.div`
  background-image: url(${bunting});
  background-repeat: repeat-x;
  background-position: center;
  transform: rotate(-6deg);
  position: absolute;
  top: 280px;
  width: 200%;
  height: 150px;
  left: -20px;
`

const Bunting2 = styled(Bunting)`
  transform: rotate(6deg) scaleX(-1);
  top: 800px;
`

const Body = styled.main`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 130px;
  position: relative;
  &::before {
    content: '';
    border-top: 1px dashed rgba(0,0,0,0.25);
    border-bottom: 1px dashed rgba(255,255,255,0.25);
    position: absolute;
    display: block;
    width: 100%;
    top: 13px;
  }
`

export const PageRouter = () => (
  <Router>
    <Container>
      <Bunting />
      <Bunting2 />
      <PageHeader />
      <Body role="main">
        {pages.map((page, index) => (
          <Route key={index} exact path={page.path} component={page.component} />
        ))}
      </Body>
      <PageFooter />
    </Container>
  </Router>
)
