import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { Star } from '../common'
import basket from '../../static/images/basket.png'
import chicken from '../../static/images/chicken.png'

export const Registry = () => {
  const Section = styled.section`
    margin: auto;
    background: #fffbef;
    border-radius: 8px / 50px;
    box-shadow: 0 0 0 3px #2c2e38 inset, 0 0 12px 7px rgba(0,0,0,0.2);
    padding: 10px 10px 30px;
    width: 70%;
    text-align: center;
    font-size: 20px;
    font-family: 'Srisakdi', cursive;
    min-height: 500px;
  `

  const First = styled.div`
    display: flex;
    align-items: center;
  `

  const Image = styled.img`
    margin: 0 25px 15px;
    max-height: 170px;
    @media (max-width: 768px) {
      display: none;
    }
  `

  const TextBoxes = styled.span`
    margin: 10px 40px;
  `
  const Anchor = styled.a`
    color: red;
    &:hover {
      color: #333;
    }
  `
  const Button = styled.a`
    background: rgb(175,213,193);
    color: #fffbef;
    border-radius: 8px;
    padding: 10px;
    font-family: 'BazarMedium', 'Palatino', sans-serif;
    font-size: 24px;
    cursor: pointer;
    text-shadow: 3px 3px 0px #2c2e38, 5px 5px 0px #5c5f72;
    text-transform: uppercase;
    box-shadow: 3px 3px 0px #2c2e38;
    transition: box-shadow 300ms ease;
    text-decoration: none;
    &:last-child {
      margin-left: 8px;
    }
    &:hover {
      box-shadow: 3px 3px 0px #2c2e38, 5px 5px 0px #5c5f72;
    }
    @media (max-width: 768px) {
      font-size: 20px;
    }
  `

  // const Image2 = styled.img`
  //   max-height: 90px;
  // `

  const Text3 = styled.p`
    font-weight: bold;
    font-size: 24px;
  `

  const Text4 = styled.p`
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    width: 100%;
  `

  const Div = styled.div`
    display: flex;
    justify-content: center;
  `

  const Hr = styled.p`
    &::after {
      content: '↭';
      width: 60%;
      height: 3px;
      background-color: #2c2338;
      display: block;
      margin: auto;
      margin-top: 20px;
      margin-bottom: 35px;
    }
  `
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <div>
        <h1><Star />Registry<Star /></h1>
      </div>
      <Section>
        <First>
          <TextBoxes>
            <Text4>Please take a moment to consider donating to Farm Sanctuary, a charity that is near and dear to our hearts.</Text4>
            <Div>
              <Image src={chicken} />
              <div>
              <Text3>You can learn more about <Anchor href="https://www.farmsanctuary.org/the-sanctuaries/" target="_blank">Farm Sanctuary here</Anchor></Text3>
              <Text3>
                <Button target="_blank" href="https://secure2.convio.net/fsi/site/Donation2?df_id=6500&6500.donation=form1&set.custom.Text_2=Rachel%20%26%20Matt%20Are%20Wed&set.custom.Item_3=true&set.custom.Text_10=Rachel%20%26%20Matt&set.custom.Text_11=2339%209th%20St&set.custom.Text_12=Berkeley%2C%20CA%2094710&set.custom.Text_13=3606094285&set.custom.Text_14=matthew.rst%40gmail.com">make a donation in our names</Button>
              </Text3>
              </div>
            </Div>
            <Hr />
            <Div>
              <div>
                <Text3>We've also created a more conventional gift registry</Text3>
                <Button target="_blank" href="https://www.amazon.com/wedding/matthew-rust-rachel-baikie-rick-oakland-september-2019/registry/3AVDGF0TCSXW9">Check it out on Amazon</Button>
              </div>
              <Image src={basket} />
            </Div>
            {/* <Image2 src="https://becore.com/wp-content/uploads/2017/09/amazon-logo-png-image-69429.png" /> */}
          </TextBoxes>
        </First>
      </Section>
    </>
  )
  }
