import React, { useEffect } from 'react'
import styled from '@emotion/styled'

import one from '../../static/images/mattRachel/1.jpg'
import four from '../../static/images/mattRachel/4.jpg'
import six from '../../static/images/mattRachel/6.jpg'
import seven from '../../static/images/mattRachel/7.png'
import eight from '../../static/images/mattRachel/8.jpg'
import nine from '../../static/images/mattRachel/9.jpg'
import ten from '../../static/images/mattRachel/10.jpg'
import eleven from '../../static/images/mattRachel/11.jpg'
import twelve from '../../static/images/mattRachel/12.jpg'
import thirteen from '../../static/images/mattRachel/13.png'
import fourteen from '../../static/images/mattRachel/14.jpg'
import fifteen from '../../static/images/mattRachel/15.jpg'
import sixteen from '../../static/images/mattRachel/16.jpg'
import seventeen from '../../static/images/mattRachel/17.jpg'
import nineteen from '../../static/images/mattRachel/19.png'
import twenty from '../../static/images/mattRachel/20.jpg'
import twentyThree from '../../static/images/mattRachel/23.jpg'
import heart from '../../static/images/heart.jpg'

import dragula from 'dragula'


const ImageContainer = styled.article`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: 920px;
  margin: auto;
`
const Overflow = styled.div`
  width: 100%;
  overflow: scroll;
`
const ImageSizer = styled.span`
  position: relative;
  margin-bottom: 10px;
  width: 300px;
  &:not(:last-child) {
    margin-right: 10px;
  }
`
const Image = styled.img`
  border-radius: 8px / 50px;
  box-shadow: 0 0 0 3px #2c2e38 inset, 0 0 12px 7px rgba(0,0,0,0.2);
  border: 2px solid #fffbef;
  border-bottom-width: 50px;
  width: 100%;
`
const Caption = styled.span`
  font-family: 'Srisakdi',cursive;
  position: absolute;
  bottom: 15px;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 24px;
`

export const Photos = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  useEffect(() => {
    const rows = [
      document.getElementById('one'),
      document.getElementById('two'),
      document.getElementById('three'),
      document.getElementById('four'),
      document.getElementById('five'),
      document.getElementById('six'),
      document.getElementById('seven'),
      document.getElementById('eight'),
      document.getElementById('nine'),
    ]
    dragula({ containers: rows as Element[] });
  })
  return (
    <>
    <div>
      <h1>Some Photos of Us</h1>
    </div>
    <Overflow>
      <ImageContainer id="one">
        <ImageSizer>
          <Image src={nine} />
          <Caption>Gatsby Picnic 2018</Caption>
        </ImageSizer>
        <ImageSizer>
          <Image src={heart} />
        </ImageSizer>
        <ImageSizer>
          <Image src={one} />
          <Caption>At Home 2018</Caption>
        </ImageSizer>
      </ImageContainer>
      <ImageContainer id="two">
        <ImageSizer>
          <Image src={six} />
          <Caption>Viva Las Vegas 2018</Caption>
        </ImageSizer>
        <ImageSizer>
          <Image src={ten} />
            <Caption>Car show 2018</Caption>
        </ImageSizer>
        <ImageSizer>
          <Image src={four} />
          <Caption>Golden Gate Park 2017</Caption>
        </ImageSizer>
      </ImageContainer>
      <ImageContainer id="three">
        <ImageSizer>
          <Image src={twenty} />
          <Caption>Laytonville 2018</Caption>
        </ImageSizer>
        <ImageSizer>
          <Image src={twentyThree} />
          <Caption>Glamour shot 2017</Caption>
        </ImageSizer>
        <ImageSizer>
          <Image src={eight} />
          <Caption>Pride 2018</Caption>
        </ImageSizer>
      </ImageContainer>
      <ImageContainer id="four">
      </ImageContainer>
      <ImageContainer id="five">
      </ImageContainer>
      <ImageContainer id="six">
        <ImageSizer>
          <Image src={sixteen} />
          <Caption>Viva Las Vegas 2017</Caption>
        </ImageSizer>
        <ImageSizer>
          <Image src={fourteen} />
          <Caption>Monterey 2016</Caption>
        </ImageSizer>
        <ImageSizer>
          <Image src={fifteen} />
          <Caption>We bought a house! 2016</Caption>
        </ImageSizer>
      </ImageContainer>
      <ImageContainer id="seven">
        <ImageSizer>
          <Image src={eleven} />
          <Caption>Pride 2015</Caption>
        </ImageSizer>
        <ImageSizer>
          <Image src={nineteen} />
          <Caption>Steampunk cruise 2013</Caption>
        </ImageSizer>
          <ImageSizer>
            <Image src={seventeen} />
            <Caption>Iggy Pop 2017</Caption>
          </ImageSizer>
      </ImageContainer>
      <ImageContainer id="eight">
        <ImageSizer>
          <Image src={thirteen} />
          <Caption>NYE at Reed 2009</Caption>
        </ImageSizer>
          <ImageSizer>
            <Image src={seven} />
            <Caption>Reed College 2008</Caption>
          </ImageSizer>
        <ImageSizer>
          <Image src={twelve} />
          <Caption>Renn Fayre 2008</Caption>
        </ImageSizer>
      </ImageContainer>
    </Overflow>
  </>
)}
