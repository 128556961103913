import React from 'react'
import styled from '@emotion/styled'

const Small = styled.span`
  font-size: 13px;
  padding: 10px;
  vertical-align: middle;
`

export const Star = () => (
  <Small>★</Small>
)
