/** @jsx jsx */

import React from 'react'
import { Global, jsx, css } from "@emotion/core"
import styled from '@emotion/styled'
import { PageRouter } from '../router'
import grain from '../../static/images/grain.png'
import bannerLeft from '../../static/images/banner-left.png'
import bannerRight from '../../static/images/banner-right.png'
import bannerMiddle from '../../static/images/banner-middle.png'

const GlobalStyles = css`
@font-face {
  font-family: 'BazarMedium';
  src: url('http://www.midwinter-dg.com/blog_demos/css-text-shadows/fonts/bazar-webfont.eot');
  src: url('http://www.midwinter-dg.com/blog_demos/css-text-shadows/fonts/bazar-webfont.eot?iefix') format('eot'),
        url('http://www.midwinter-dg.com/blog_demos/css-text-shadows/fonts/bazar-webfont.woff') format('woff'),
        url('http://www.midwinter-dg.com/blog_demos/css-text-shadows/fonts/bazar-webfont.ttf') format('truetype'),
        url('http://www.midwinter-dg.com/blog_demos/css-text-shadows/fonts/bazar-webfont.svg#webfont4U38ahXF') format('svg');
  font-weight: normal;
  font-style: normal;
}
  html,
  body{
    margin: 0;
    padding: 0;
  }
  body {
    background-image: url(${grain});
    background-color: rgb(175,213,193);
  }
  #root {
    position: relative;
  }
  h1 {
    color: #fffbef;
    font-size: 48px;
    background-image: url(${bannerMiddle});
    background-size: 20px;
    padding-top: 40px;
    background-repeat: repeat-x;
    text-align: center;
    position: relative;
    text-shadow: 3px 3px 0px #2c2e38, 5px 5px 0px #5c5f72;
    font-family: 'BazarMedium', 'Palatino', sans-serif;
    &::after {
      content: '';
      background-image: url(${bannerLeft});
    position: absolute;
    left: -100px;
    height: 100px;
    width: 100px;
    top: 0;
    background-size: 100px;
    background-repeat: no-repeat;
    }
    &::before {
      content: '';
      background-image: url(${bannerRight});
      position: absolute;
      right: -100px;
      height: 100px;
      width: 100px;
      top: 0;
      background-size: 100px;
      background-repeat: no-repeat;
    }
  }
`

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  ['*']: {
    box-sizing: border-box;
  }
`

const AppCover = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  box-shadow: 0 0 25px 15px rgba(0, 0, 0, 0.2) inset;
  z-index: 2000;
`

export const App: React.JSXElementConstructor<{}> = () => (
  <AppContainer>
    <Global styles={() => GlobalStyles} />
    <PageRouter />
    <AppCover />
  </AppContainer>
)
