import React, { useState } from 'react'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import { pages } from './page-list'
import { Star } from '../common'
import grain from '../../static/images/grain.png'
import { slide as Menu } from 'react-burger-menu'

const Header = styled.header`
  display: flex;
  width: 100%;
  height: 130px;
  position: fixed;
  background-color: rgb(175,213,193);
  z-index: 1000;
  background-image: url(${ grain });
`

const MenuContainer = styled.div`
  display: none;
   @media (max-width: 768px) {
    display: block
  }
`

const Nav = styled.nav`
  margin: 0 auto;
  width: 100%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  position: relative;
  display: flex;
  align-items: center;
  &::before {
    content: '';
    border-top: 1px dashed rgba(0, 0, 0, 0.25);
    border-bottom: 1px dashed rgba(255, 255, 255, 0.25);
    position: absolute;
    display: block;
    width: 100%;
    bottom: 10px;
  }
`
const NavList = styled.ul`
  padding: 0;
  margin: 0;
  display: flex;
  list-style: none;
  margin: auto;
`
const NavListItem = styled.li`
  font-size: 16px;
`

const NavListResponsive = styled(NavList)`
  @media (max-width: 768px) {
    display: none
  }
`

const NavLink = styled(Link)`
  border-radius: 10px;
  text-decoration: none;
  font-size: 27px;
  padding: 0 15px;
  text-transform: uppercase;
  height: 90px;
  display: flex;
  align-items: center;
  color: #fffbef;
  text-shadow: 3px 3px 0px #2c2e38, 5px 5px 0px #5c5f72;
  font-family: 'BazarMedium', 'Palatino', sans-serif;
  &:hover {
    background: rgba(0, 0, 0, 0.2);
    box-shadow: -1px -1px 2px rgba(0, 0, 0, 0.15) inset;
  }
  @media (max-width: 1000px) {
    padding: 0 3px;
    font-size: 20px;
  }
`

const menuStyles: any = {
  bmOverlay: {
    top: 0
  },
  bmMenuWrap: {
    background: `rgb(175, 213, 193)`,
    top: 0
  },
  bmBurgerButton: {
    position: 'fixed',
    width: '36px',
    height: '30px',
    left: '36px',
    top: '46px'
  },
  bmBurgerBars: {
    background: '#373a47'
  },
  bmBurgerBarsHover: {
    background: '#a90000'
  },
}

export const PageHeader = () => {
  const [ menuOpen, setMenuOpen ] = useState(false)
  return (
    <Header role="header">
      <Nav role="navigation">
        <MenuContainer>
          <Menu styles={menuStyles} isOpen={menuOpen}
            onStateChange={(state) => setMenuOpen(state.isOpen)}>
            <NavList>
              {pages.map((page, index) => (
                <NavListItem key={index}>
                  <NavLink onClick={() => setMenuOpen(false)} to={page.path}>
                    <Star />
                    <span>
                      {page.linkText}
                    </span>
                    <Star />
                  </NavLink>
                </NavListItem>
              ))}
            </NavList>
          </Menu>
        </MenuContainer>
        <NavListResponsive>
          {pages.map((page, index) => {
            if (page.linkText === 'Map') return
            return (
              <NavListItem key={index}>
                <NavLink to={page.path}>
                  <Star />
                  <span>
                    {page.linkText}
                  </span>
                  <Star />
                </NavLink>
              </NavListItem>
            )
          })}
        </NavListResponsive>
      </Nav>
    </Header>
  )
}
