import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { Star } from '../common'
import car from '../../static/images/car-graphic.png'

export const Local = () => {
  const Section = styled.section`
    margin: auto;
    background: #fffbef;
    border-radius: 8px / 50px;
    box-shadow: 0 0 0 3px #2c2e38 inset, 0 0 12px 7px rgba(0,0,0,0.2);
    padding: 10px;
    width: 70%;
    text-align: center;
    font-size: 20px;
    font-family: 'Srisakdi', cursive;
    min-height: 500px;
  `

  const First = styled.div`
    display: flex;
    align-items: center;
  `

  const Div = styled.div`
    display: flex;
    justify-content: center;
  `

  const Image = styled.img`
    height: 100px;
    float: left;
    margin: 0 10px 15px 20px;
    @media (max-width: 768px) {
      display: none;
    }
  `

  const TextBoxes = styled.span`
    margin-right: 40px;
    margin-top: 10px;
    @media (max-width: 768px) {
      margin-left: 40px;
    }
  `

  const Text2 = styled.p`
    font-size: 34px;
    flex: 1;
    color: red;
    margin: 0;
    padding-top: 10px;
    text-shadow: 1px 1px 3px #bbb7b7;
    font-family: 'BazarMedium', 'Palatino', sans-serif;
    text-transform: uppercase;
    text-align: center;
  `

  const Text3 = styled.li`
    font-weight: bold;
    font-size: 24px;
    margin: 1em 40px 1em 20px;
    &::after {
      content: '↭';
      width: 60%;
      height: 20px;
      display: block;
      margin: 10px auto;
      text-align: center;
    }
  `

  const Text5 = styled.p`
    text-align: left;
    font-size: 24px;
    margin: 1em 40px 1em 20px;
  `

  const Text4 = styled.ul`
    padding: 0;
    margin: 0 0 0 40px;
    text-align: left;
  `
  const Hr = styled.p`
    text-align: center;
    &::after {
      content: '';
      width: 60%;
      height: 3px;
      background-color: #2c2338;
      display: block;
      margin: auto;
      margin-top: 20px;
      margin-bottom: 35px;
    }
  `

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <div>
        <h1><Star />Local<Star /></h1>
      </div>
      <Section>
        <First>
          <TextBoxes>
            <Text2>Lots to do and see!</Text2>
            <Div>
              <Image src={car} />
              <div>
                <Text5>
                  Our venue is close to lots of public transportation, restaurants, and night life in uptown Oakland.
                </Text5>
                <Text5>
                  Here are a few suggestions of fun things to do nearby:
                </Text5>
              </div>
            </Div>
            <Hr />
            <Text4>
              <Text3><a href="https://museumca.org/" target="_blank">Oakland Museum of California</a> is one of the top-rated Bay Area museums to explore California art, history, and natural sciences.</Text3>
              <Text3><a href="https://www.forbiddenislandalameda.com/" target="_blank">Forbidden Island</a>, close by in Alameda, is our favorite tiki bar</Text3>
              <Text3><a href="https://www.ebparks.org/images/Assets/files/parks/crown_beach/Crown_Beach_2250w_32c.gif" target="_blank">Bayside park</a>, Crab Cove, and Alameda beach are great places to walk around with beautiful views of San Francisco across the bay.</Text3>
              <Text3><a href="https://cuesa.org/markets/jack-london-square-farmers-market-sunday" target="_blank">Jack London Square</a> farmers market, on Sundays 10am-3pm, is vibrant waterfront marketplace. Just a short walk away.</Text3>
            </Text4>
          </TextBoxes>
        </First>
      </Section>
    </>
  )
}
