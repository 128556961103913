import { Home, Where, Rsvp, Registry, Local, Photos, Map } from '../pages'

interface pageDefinition {
  path: string
  component: () => JSX.Element
  linkText: string
}

export const pages: pageDefinition[] = [
  {
    path: `/`,
    component: Home,
    linkText: `Home`,
  },
  {
    path: `/where`,
    linkText: `Where & When`,
    component: Where,
  },
  {
    path: `/rsvp`,
    linkText: `RSVP`,
    component: Rsvp,
  },

  {
    path: `/registry`,
    linkText: `Registry`,
    component: Registry,
  },
  {
    path: `/local`,
    linkText: `In the area`,
    component: Local,
  },
  {
    path: `/photos`,
    linkText: `Photos`,
    component: Photos,
  },
  {
    path: `/map`,
    linkText: `Map`,
    component: Map,
  },
]
