import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { Star } from '../common'
import compass from '../../static/images/compass.png'
import hotel from '../../static/images/hotel.png'

export const Where = () => {

  const Section = styled.section`
    margin: auto;
    background: #fffbef;
    border-radius: 8px / 50px;
    box-shadow: 0 0 0 3px #2c2e38 inset, 0 0 12px 7px rgba(0,0,0,0.2);
    padding: 10px;
    width: 70%;
    text-align: left;
    font-size: 20px;
    font-family: 'Srisakdi', cursive;
    min-height: 500px;
  `

  const First = styled.div`
    display: flex;
    align-items: center;
  `

  const Image = styled.img`
    height: 160px;
    margin: 0 25px 15px;
    @media (max-width: 768px) {
      display: none;
    }
  `

  const Red = styled.span`
    color: red;
  `

  const TextBoxes = styled.span`
    margin-top: 10px;
    margin-bottom: 40px;
  `

  const Text1 = styled.p`
    margin: 20px 5px 10px;
  `

  const Text2 = styled.p`
    text-align: center;
    font-size: 34px;
    flex: 1;
    color: red;
    margin: 0;
    padding-top: 10px;
    text-shadow: 1px 1px 3px #bbb7b7;
    font-family: 'BazarMedium', 'Palatino', sans-serif;
    text-transform: uppercase;
  `

  const Text3 = styled.p`
    font-weight: bold;
    font-size: 24px;
    margin-right: 40px;
    @media (max-width: 768px) {
      margin-right: 10px;
    }
  `
  const Text35 = styled.span`
    display: block;
    font-weight: bold;
    font-size: 24px;
    margin-right: 40px;
    @media (max-width: 768px) {
      margin-right: 10px;
    }
  `

  const TextUl = styled.ul`
    font-weight: bold;
    font-size: 24px;
    margin-right: 40px;
    @media (max-width: 768px) {
      margin-right: 10px;
    }
  `
  const TextLi = styled.li`
    margin-bottom: 8px;
  `

  const Text4 = styled.p`
    font-weight: bold;
    font-size: 24px;
    margin-right: 40px;
    margin-left: 40px;
    text-align: center;
  `

  const Div = styled.div`
    display: flex;
    justify-content: center;
  `

  const Div2 = styled.div`
    display: flex;
    justify-content: center;
    @media (max-width: 768px) {
      flex-direction: column;
    }
  `

  const Bold = styled.span`
    font-weight: bold;
    display: inline-block;
    width: 85px;
  `

  const Frame = styled.div`
    width: 100%;
    height: 400px;
    border: none;
    margin-right: 8px;
    margin-left: 16px;
    @media (max-width: 768px) {
      margin-left: 8px;
      width: calc(100% - 16px);
    }
  `

  const Hr = styled.p`
    text-align: center;
    &::after {
      content: '↭';
      width: 60%;
      height: 3px;
      background-color: #2c2338;
      display: block;
      margin: auto;
      margin-top: 20px;
      margin-bottom: 35px;
    }
  `

  const Button = styled.a`
    background: rgb(175,213,193);
    color: #fffbef;
    border-radius: 8px;
    padding: 10px;
    font-family: 'BazarMedium', 'Palatino', sans-serif;
    font-size: 24px;
    cursor: pointer;
    text-shadow: 3px 3px 0px #2c2e38, 5px 5px 0px #5c5f72;
    text-transform: uppercase;
    box-shadow: 3px 3px 0px #2c2e38;
    transition: box-shadow 300ms ease;
    text-decoration: none;
    &:last-child {
      margin-left: 8px;
    }
    &:hover {
      box-shadow: 3px 3px 0px #2c2e38, 5px 5px 0px #5c5f72;
    }
    @media (max-width: 768px) {
      font-size: 20px;
    }
  `

  useEffect(() => {
    const script1 = document.createElement("script")

    script1.src = "/map.js"
    script1.id = `mapScript1`

    const script = document.createElement("script")

    script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyDIdgXoi1H6qEUzq3011_kN5Gx4ifwx_4I&v=3.36&language=en&libraries=places,geometry&callback=createMap"
    script.defer = true
    script.async = true
    script.id = `mapScript`

    if (!document.getElementById('mapScript')) {
      document.body.appendChild(script1)
      document.body.appendChild(script)
    }
    window.scrollTo(0, 0)

    return function killAllHumans() {
      const s = document.getElementById('mapScript')
      const s1 = document.getElementById('mapScript1')
      if (s && s.parentNode) {
        s.parentNode.removeChild(s)
      }
      if (s1 && s1.parentNode) {
        s1.parentNode.removeChild(s1)
      }
    }
  }, [])

  return (
    <>
      <div>
        <h1><Star />When & Where<Star /></h1>
      </div>
      <Section>
        <First>
          <TextBoxes>
            <Text2>Location</Text2>
            <Div>
              <Image src={compass} />
              <div>
                <Text3>Our wedding reception will be held in the back garden of the Pardee Home Museum. It is located at <em>672 11th St, Oakland, CA 94607</em>.</Text3>
                <Text3><Red>Please enter directly into the back garden through the gate on Castro St, between 11th St and 12th St.</Red></Text3>
                <Text3>The event will start at 3:30pm on 9/29/19 and will run until around 10pm. Here is a tentative schedule of the day's events:</Text3>
                <ul>
                  <li><Bold>3:30 pm</Bold>  Bar open - cocktails and appetizers</li>
                  <li><Bold>4:30 pm</Bold>  Speeches</li>
                  <li><Bold>5:00 pm</Bold>  Get seated for dinner</li>
                  <li><Bold>7:00 pm</Bold>	 Dancing starts</li>
                  <li><Bold>10:00 pm</Bold>	Last Song</li>
                </ul>
              </div>
            </Div>
            <Hr />
            <Div2>
              <Frame id="map"></Frame>
              <div>
                <Text3>From the Oakland Airport:</Text3>
                <TextUl>
                  <TextLi>Follow I-880&nbsp;N to 11th&nbsp;St.</TextLi>
                  <TextLi>Take Exit&nbsp;42A, Keep right to continue on Exit&nbsp;1A.</TextLi>
                  <TextLi>Follow signs for 11th&nbsp;St/14th&nbsp;St</TextLi>
                </TextUl>
              </div>
            </Div2>
            <Text4>
              If you're driving directly here rather than staying at the hotel (see below), there is a 24-hour parking lot located conveniently at 577 11th St.
            </Text4>
            <Text4>
              There is also generally plenty of street parking nearby on Castro Street.
            </Text4>
            <Hr />
            <Text2>Lodging</Text2>
            <Div>
              <Image src={hotel} />
              <div>
                <Text3>
                  There are many hotels located within walking distance of our venue.
                  <br />Marriott City Center has a limited number of rooms available for a discounted block rate.
                </Text3>
                <Text35>
                  <Text1>Closest option with our block rate</Text1>
                  <Button href="https://www.marriott.com/event-reservations/reservation-link.mi?id=1556327184748&amp;key=GRP&amp;app=resvlink" target="_blank">
                    Marriott City Center
                  </Button>
                </Text35>
                <Text35>
                  <Text1>Nice alternative</Text1>
                  <Button href="https://www.marriott.com/hotels/travel/oakcd-courtyard-oakland-downtown/" target="_blank">
                    Courtyard Downtown
                  </Button>
                </Text35>
                <Text35>
                  <Text1>Further away, but on the water</Text1>
                  <Button href="https://www.jdvhotels.com/hotels/california/oakland/waterfront-hotel" target="_blank">
                    Waterfront Hotel
                  </Button>
                </Text35>
                <Text35>
                  <Text1>Cheaper historic hotel</Text1>
                  <Button href="http://www.thewashingtoninn.connectotels.com/" target="_blank">
                    Washington Inn
                  </Button>
                </Text35>
              </div>
            </Div>
          </TextBoxes>
        </First>
      </Section>
    </>
  )
}
