import React, { useState, useEffect, Fragment } from 'react'
import styled from '@emotion/styled'
import { Loading, Star } from '../common'
import gramophone from '../../static/images/telephone.png'
import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik'
import classNames from 'classnames'

const Section = styled.section`
  margin: auto;
  background: #fffbef;
  border-radius: 8px / 50px;
  box-shadow: 0 0 0 3px #2c2e38 inset, 0 0 12px 7px rgba(0,0,0,0.2);
  padding: 10px 20px;
  width: 70%;
  text-align: center;
  font-size: 20px;
  font-family: 'Srisakdi', cursive;
  min-height: 500px;
  @media (max-width: 768px) {
    width: 85%;
  }
`

const First = styled.div`
  display: flex;
  align-items: center;
`

const Image = styled.img`
  width: 180px;
  float: left;
  margin: 20px 25px 15px;
  @media (max-width: 768px) {
    display: none;
  }
`

const RsvpForm = styled.div`
  font-family: Helvetica;
  position: relative;
  margin-bottom: 36px;
`

const Label = styled.label`
  margin-bottom: 5px;
  margin-left: 5px;
  display: flex;
  flex-direction: column;
  max-width: 40%;
  text-align: left;
  font-family: 'Srisakdi', cursive;
  @media (max-width: 768px) {
    max-width: 80%;
  }
`

const Row = styled.div`
  display: flex;
  text-align: left;
  margin-top:8px;
  font-family: 'BazarMedium', 'Palatino', sans-serif;
  & ::placeholder {
    opacity: .5;
  }
`

const PaddedRow = styled(Row)`
  padding: 0 8px;
`

const PaddedRowFlexy = styled(PaddedRow)`
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const Legend = styled.legend`
  font-family: 'BazarMedium', 'Palatino', sans-serif;
`

const FancyForm = styled(Form)<{ submitting: string }>`
position: relative;
  &:after {
    content: '';
    background: rgba(0, 0, 0, .2);
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    position: ${props => props.submitting === 'cover' ? 'absolute' : 'relative'};
  }
`

const RadioField = styled.label`
  padding-left: 8px;
  font-family: 'Srisakdi', cursive;
`

interface RadioData {
  field: {
    name: string
    value: string
    onChange: (e: React.SyntheticEvent) => void
    onBlur: (e: React.SyntheticEvent) => void
  }
  id: string
  label: string
  className: string
}
const RadioButton = ({
  field: { name, value, onChange, onBlur },
  id,
  label,
  className,
  ...props
}: RadioData) => {
  return (
    <Row>
      <input
        name={name}
        id={id}
        type="radio"
        value={id} // could be something else for output?
        checked={id === value}
        onChange={onChange}
        onBlur={onBlur}
        className={classNames("radio-button")}
        {...props}
      />
      <RadioField htmlFor={id}>{label}</RadioField>
    </Row>
  );
};

interface RadioGroupData {
  value: string
  error: string
  touched: boolean
  id: string
  label: string
  className: string
  children: React.ReactElement[]
}
const RadioButtonGroup = ({
  value,
  error,
  touched,
  id,
  label,
  className,
  children,
}: RadioGroupData) => {
  const classes = classNames(
    "input-field",
    {
      "is-success": value || (!error && touched), // handle prefilled or user-filled
      "is-error": !!error && touched
    },
    className
  );

  return (
    <div className={classes}>
      <fieldset>
        <Legend>{label}</Legend>
        {children}
        {touched && <ErrorMessage name={name} component="div" />}
      </fieldset>
    </div>
  );
};

const RadioSuperGroup = styled(RadioButtonGroup)`
  max-width: 50%;
  margin: auto;
  @media (max-width: 768px) {
    max-width: 100%;
  }
`

const Button = styled.button`
  background: rgb(175,213,193);
  color: #fffbef;
  border-radius: 8px;
  padding: 10px;
  font-family: 'BazarMedium', 'Palatino', sans-serif;
  font-size: 24px;
  cursor: pointer;
  text-shadow: 3px 3px 0px #2c2e38, 5px 5px 0px #5c5f72;
  text-transform: uppercase;
  box-shadow: 3px 3px 0px #2c2e38;
  transition: box-shadow 300ms ease;
  &:last-child {
    margin-left: 8px;
  }
  &:hover {
    box-shadow: 3px 3px 0px #2c2e38, 5px 5px 0px #5c5f72;
  }
  @media (max-width: 768px) {
    font-size: 20px;
  }
`

const LoadingContainer = styled.div`
  position: absolute;
  bottom: 100px;
  left: 0;
  right: 0;
`

const FancyField = styled(Field)`
  border: 1px solid #2c2e38;
  padding: 10px;
  background: white;
  border-radius: 3px;
  margin: 3px 3px 3px 0;
  font-size: 16px;
`

const FancyTextbox = styled(FancyField)`
  width: 380px;
  height: 60px;
  max-width: 100%;
`

const Heading = styled.h2`
  border-bottom: 1px solid #2c2e38;
  margin-bottom: 8px;
  width: 100%;
  box-shadow: 0px 1px 0 #5c5f72;
  color: red;
  font-family: 'BazarMedium', 'Palatino', sans-serif;
  text-transform: uppercase;
`

const Spacer = styled.div`
  width: 100%;
  &::after {
    content: '';
    width: 100%;
    height: 2px;
    background-color: #2c2338;
    display: block;
    margin-bottom: 20px;
    text-align: center;
  }
`
const UnSpacer = styled(Spacer)`
  transform: scaleY(-1);
`

const TextBoxes = styled.span`
  margin-right: 40px;
  margin-left: 40px;
  margin-top: 10px;
  @media (max-width: 768px) {
    margin-left: 10px;
    margin-right: 10px;
  }
`

const Text2 = styled.p`
  font-size: 34px;
  flex: 1;
  color: red;
  margin: 0;
  padding-top: 10px;
  text-shadow: 1px 1px 3px #bbb7b7;
  font-family: 'BazarMedium', 'Palatino', sans-serif;
  text-transform: uppercase;
`

const Text3 = styled.p`
  font-weight: bold;
  font-size: 24px;
  &::after {
    content: '↭';
    width: 60%;
    height: 3px;
    background-color: #2c2338;
    display: block;
    margin: auto;
    margin-top: 20px;
  }
`

interface GuestInfo {
  first: string
  last: string
  allergies: string,
}
interface FormData {
  guests: GuestInfo[]
  coming: 'yes' | 'no'
  message: string,
}

const initialFormData: FormData = {
  coming: 'yes',
  message: '',
  guests: [
    {
      first: '',
      last: '',
      allergies: '',
    },
    {
      first: '',
      last: '',
      allergies: '',
    },
  ]
}

export const Rsvp = () => {
  const [ responseToPost, setResponseToPost ] = useState('')

  const handleSubmit = async (values: FormData) => {
    const response = await fetch('/api/email', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(values),
    })
    const body = await response.text()
    setResponseToPost(body)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <div>
        <h1><Star />RSVP<Star /></h1>
      </div>
      <Section>
        <First>
          <Image src={gramophone} />
          <TextBoxes>
            <Text2>Please let us know if you'll be there!</Text2>
            <Text3><Star />RSVP by 8/1/2019<Star /></Text3>
          </TextBoxes>
        </First>
        {responseToPost ? <h2>Thank you for your response! {responseToPost}</h2> : (
          <RsvpForm>
            <Formik
              initialValues={initialFormData}
              onSubmit={(values, { setSubmitting }) => {
                handleSubmit(values).then(
                  () => setSubmitting(false)
                )
              }}
            >
              {({ values, errors, touched, isSubmitting }) => (
                <>
                <FancyForm submitting={isSubmitting ? 'cover' : 'all good'}>
                  <FieldArray
                    name="guests"
                    render={(arrayHelpers) => (
                      <>
                        <RadioSuperGroup
                          id="radioGroup"
                          label="Can you make it?"
                          value={(values as any).radioGroup}
                          error={(errors as any).radioGroup}
                          touched={(touched as any).radioGroup}
                          className='radio'
                        >
                          <Field
                            component={RadioButton}
                            name="coming"
                            id="yes"
                            label="Yessiree Bob!"
                          />
                          <Field
                            component={RadioButton}
                            name="coming"
                            id="no"
                            label="Sadly No"
                          />
                        </RadioSuperGroup>
                        {values.guests.map((guest, index) => (
                          <Fragment key={index}>
                            <Row>
                              <Heading>Guest {index + 1}: {guest.first} {guest.last}</Heading>
                            </Row>
                            <PaddedRowFlexy>
                              <Label>
                                First Name
                                <FancyField placeholder={index % 2 ? "Micky" : "Minnie"} type="input" name={`guests.${index}.first`} />
                              </Label>
                              <Label>
                                Last Name
                                <FancyField placeholder="Mouse" type="input" name={`guests.${index}.last`} />
                              </Label>
                            </PaddedRowFlexy>
                            <PaddedRow>
                              {values.coming !== 'no' && (
                                <Label>
                                  Any Food Allergies or Restrictions?
                                  <FancyField placeholder="Ah-choo" type="input" name={`guests.${index}.allergies`} />
                                </Label>
                              )}
                            </PaddedRow>
                          </Fragment>
                        ))}
                        <Row>
                          <Spacer />
                        </Row>
                        <PaddedRow>
                          <Label>
                            Anything to Add?
                            <FancyTextbox placeholder="I've got something to say" type="input" name="message" component="textarea" />
                          </Label>
                        </PaddedRow>
                        <Row>
                          <UnSpacer />
                        </Row>
                        <Row>
                          <Button type="button" onClick={() => arrayHelpers.push({
                            first: '',
                            last: '',
                            allergies: '',
                            message: '',
                          })}>+ Add another guest</Button>
                          <Button type="submit" disabled={isSubmitting}>Submit</Button>
                        </Row>
                      </>
                    )}
                  />
                </FancyForm>
                  {isSubmitting && <LoadingContainer><Loading /></LoadingContainer>}
                </>
            )}
            </Formik>
          </RsvpForm>
        )}
      </Section>
    </>
  )
}
