import React, {
  useEffect
} from 'react'
import styled from '@emotion/styled'
import pure98 from '../../static/images/pure98.jpg'
import { Star } from '../common'

export const Home = () => {

  const ImageContainer = styled.section`
    margin: auto;
    background: #fffbef;
    border-radius: 8px / 50px;
    box-shadow: 0 0 0 3px #2c2e38 inset, 0 0 12px 7px rgba(0,0,0,0.2);
    display: flex;
    padding: 10px;
    width: 70%;
    text-align: center;
    font-size: 20px;
    align-items: center;
    font-family: 'Srisakdi', cursive;
     @media (max-width: 768px) {
       width: 85%;
    }
  `

  const Image = styled.img`
    height: 500px;
    margin: 3px 8px;
    display: inline-block;
    box-shadow: 0 0 0 2px #2c2e38, 1px 1px 5px rgba(0,0,0,0.25);
    padding: 2px;
    @media (max-width: 1000px) {
      display: none;
    }
  `
  const Text = styled.p`
    margin: 0;
    flex: 1;
  `
  const Text2 = styled.p`
    font-size: 34px;
    flex: 1;
    color: red;
    margin: 0;
    padding-top: 10px;
    text-shadow: 1px 1px 3px #bbb7b7;
    font-family: 'BazarMedium', 'Palatino', sans-serif;
  `
  const Text1 = styled.p`
    margin-top: 0;
    margin-bottom: 5px;
    font-size: 26px;
    &::after {
      content: '↭';
      width: 60%;
      height: 3px;
      background-color: #2c2338;
      display: block;
      margin: auto;
      margin-top: 20px;
      font-size: 20px;
      transform: scaleY(-1);
    }
  `
  const Text3 = styled.p`
    margin-top: 0;
    &::after {
      content: '↭';
      width: 60%;
      height: 3px;
      background-color: #2c2338;
      display: block;
      margin: auto;
      margin-top: 20px;
    }
  `
  const Black = styled.span`
    color: #2c2e38;
  `
  const TextBoxes = styled.span`
    margin-right: 40px;
    margin-top: 10px;
    @media (max-width: 1000px) {
      margin-left: 40px;
    }
  `
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
    <div>
      <h1>Ladies & Gentlemen...</h1>
    </div>
      <ImageContainer>
        <Image src={pure98} />
        <TextBoxes>
          <Text2>RACHEL BAIKIE-RICK</Text2>
          <Text>and</Text>
          <Text2>MATTHEW RUST</Text2>
          <Text3>eloped!</Text3>
          <Text1>We traveled to Las Vegas to officially tie the knot in an intimate, private ceremony<br /> on April 17, 2019.<br /> But we still want to celebrate with YOU!</Text1>
          <Text2>Please join us for our wedding reception on</Text2>
          <Text2><Black><Star /></Black>SEPTEMBER 29, 2019<Black><Star /></Black></Text2>
          <Text>in Oakland, California</Text><br />
        </TextBoxes>
      </ImageContainer>
    </>
  )
}
