import React from 'react'
import styled from '@emotion/styled'

const Footer = styled.footer`
  display: flex;
  width: 100%;
  text-align: center;
  position: relative;
  padding-top: 8px;
  &::before {
    content: '';
    border-top: 1px dashed rgba(0,0,0,0.25);
    border-bottom: 1px dashed rgba(255,255,255,0.25);
    position: absolute;
    display: block;
    width: 100%;
    top: 13px;
  }
`

const Copy = styled.div`
  margin: 20px auto;
`

export const PageFooter = () => {
  const date = new Date()
  return (
    <Footer role="contentinfo">
      <Copy>&copy; matt ♥ rachel {date.getFullYear()}</Copy>
    </Footer>
  )
  }
